import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationResponse } from '../../account/models/authentication-response';
import { AuthenticatedUserService } from './authenticated-user.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CompanyPagesService } from 'src/app/company/services/company-pages.service';
import { CompanyAuthenticatedUserApiService } from './company-authenticated-user-api.service';
import { AppModalService } from './app-modal.service';
import { PendoService } from './pendo.service';

@Injectable({
  providedIn: 'root',
})
export class InitializationService {
  private receivedJwt: string = '';
  private initUser = new BehaviorSubject<boolean>(false);
  private newUser = false;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private location: Location,
    private authenticatedUserService: AuthenticatedUserService,
    private companyAuthenticatedUserApiService: CompanyAuthenticatedUserApiService,
    private companyPagesService: CompanyPagesService,
    private appModalService: AppModalService,
    private pendoService: PendoService
  ) {
    this.authenticatedUserService.getUserSubject().subscribe(user => {
      if (user) {
        if (authenticatedUserService.isCompanyUser()) {
          this.initializeCompanyData();
        } else {
          this.navigateToGenericPage(this.newUser);
          if (this.newUser) {
            this.newUser = false;
          }
        }
      }
    });
  }

  public getReceivedJwt(): string {
    return this.receivedJwt;
  }

  public setReceivedJwt(value: string) {
    this.receivedJwt = value;
  }

  authentication(newUser = false): Observable<boolean> {
    const authenticationFailedSubject = new Subject<boolean>();
    this.newUser = newUser;
    this.httpClient
      .get<AuthenticationResponse>(`${environment.serverUrl}/authentication`)
      .subscribe({
        next: res => {
          this.saveTokens(res.token, res.refreshToken);
          this.initializeApp();
          authenticationFailedSubject.next(false);
          authenticationFailedSubject.complete();
        },
        error: () => {
          authenticationFailedSubject.next(true);
          authenticationFailedSubject.complete();
          localStorage.clear();
        },
      });
    return authenticationFailedSubject.asObservable();
  }

  refreshToken(refreshToken: string, updateRoles = false): Observable<AuthenticationResponse> {
    return this.httpClient.post<AuthenticationResponse>(
      `${environment.serverUrl}/authentication/refresh`,
      { refreshToken, updateRoles }
    );
  }

  saveTokens(token: string, refreshToken: string) {
    localStorage.clear();
    this.setReceivedJwt(token);
    localStorage.setItem('token', token);
    localStorage.setItem('refresh', refreshToken);
  }

  initializeCompanyData() {
    this.companyAuthenticatedUserApiService.getUsersCompanyDetails().subscribe({
      next: response => {
        if (response.length) {
          this.companyPagesService.setUserCompanyDetails(response);
          this.pendoService.startPendo();
          this.navigateToGenericPage(false);
        } else {
          this.appModalService.showActiveCompanyDialog();
          this.setReceivedJwt('');
          localStorage.clear();
          if (!this.location.path().includes('/account')) {
            this.router.navigate(['/account/signin']);
          }
        }
      },
      error: () => {
        if (!this.location.path().includes('/account')) {
          this.router.navigate(['/account/signin']);
        }
      },
    });
  }

  initializeApp(): void {
    const token = localStorage.getItem('token');
    if (!token) {
      if (!this.location.path().includes('/account')) {
        localStorage.clear();
        this.router.navigate(['/account/signin']);
      }
      if (this.location.path().includes('/account/admin/signin') && !token) {
        localStorage.clear();
      }
      this.initUser.next(true);
      return;
    }

    this.setReceivedJwt(token);
    this.authenticatedUserService.getAuthenticatedUserApi().subscribe({
      next: user => {
        this.authenticatedUserService.setUser(user);
        this.initUser.next(true);
      },
      error: () => {
        this.initUser.next(true);
        if (!this.location.path().includes('/account')) {
          this.router.navigate(['/account/signin']);
        }
      },
    });
  }

  public getInitUser() {
    return this.initUser.asObservable();
  }

  public navigateToGenericPage(newUser: boolean) {
    if (this.authenticatedUserService.isAdmin() || this.authenticatedUserService.isResearcher()) {
      if (
        !this.location.path().includes('/admin') ||
        this.location.path().includes('/admin/signin')
      ) {
        this.router.navigate(['/admin']);
      }
      return;
    } else if (this.authenticatedUserService.isCompanyUser()) {
      if (
        !this.location.path() ||
        !this.location.path().includes('/company') ||
        this.location.path().includes('/signin') ||
        (this.location.path().includes('/company') && this.location.path().includes('/switch')) ||
        (this.location.path().includes('/company') && this.location.path().includes('/create'))
      ) {
        this.router.navigate(['/company']);
      }
      return;
    }

    if (newUser) {
      this.router.navigate(['/member/info/general']);
    } else if (!this.location.path().includes('/member')) {
      this.router.navigate(['/member']);
    }
  }
}
