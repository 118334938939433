import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { AuthenticatedUserService } from './authenticated-user.service';

@Injectable({
  providedIn: 'root',
})
export class PendoService {
  private isInitialized = false;

  constructor(private authenticatedUserService: AuthenticatedUserService) {}

  startPendo(): void {
    // TODO: add environment.production to the condition after we do testing on Test
    // Since we dont want Pendo to show to a lot of people, it should be only on prod
    if (!this.isInitialized) {
      const script: HTMLScriptElement = document.createElement('script');
      script.async = true;
      script.crossOrigin = 'anonymous';
      script.id = 'Pendo';
      script.src = `https://cdn.pendo.io/agent/static/acee0108-0da6-46b5-4ffc-4d647fcdfcf3/pendo.js`;
      script.type = 'text/javascript';
      script.onload = () => {
        this.setUserData(this.authenticatedUserService.getUser());
      };
      document.body.appendChild(script);
    }
  }
  private setUserData(user: User): void {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const pendo = (window as unknown as any).pendo;
    if (pendo && pendo.initialize) {
      pendo.initialize({
        visitor: {
          id: user.id,
          email: user.email,
        },
      });
      pendo.events?.guidesLoaded(() => {
        pendo.setGuidesDisabled(false);
        pendo.startGuides();
      });
      this.isInitialized = true;
    } else {
      this.isInitialized = false;
    }
  }
}
